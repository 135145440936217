import React, { useEffect, useState } from "react";
import axios from "axios";

// Initialize Axios instance
const api = axios.create({
  baseURL: "https://codeoceantech.pythonanywhere.com/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

// TypeScript Interfaces
interface CountryStats {
  country_code: string;
  currency: string;
  deliveredTotal: number;
  undeliveredTotal: number;
  spent: number;
}

interface Notify {
  id: number;
  child: number;
  child_name: string;
  message_type: string;
  content: string;
  created_at: string;
  delivered: boolean;
  phone_number: string;
  country_code: string;
}

const NotifyList: React.FC = () => {
  const [notifies, setNotifies] = useState<Notify[]>([]);
  const [filteredNotifies, setFilteredNotifies] = useState<Notify[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filterDelivered, setFilterDelivered] = useState<string>("all");
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [countryStats, setCountryStats] = useState<CountryStats[]>([]);
  const [euroSpend, setEuroSpend] = useState<number>(0);
  const [egpSpend, setEgpSpend] = useState<number>(0);

  // Currency rates
  const CURRENCY_RATES: { [key: string]: { rate: number; currency: string; country: string } } = {
    "+20": { rate: 0.98, currency: "EGP", country: "Egypt" },
    "+966": { rate: 0.28, currency: "EUR", country: "Saudi Arabia" },
    "+971": { rate: 0.24, currency: "EUR", country: "United Arab Emirates" },
    "+965": { rate: 0.31, currency: "EUR", country: "Kuwait" },
    "+974": { rate: 0.36, currency: "EUR", country: "Qatar" },
    "+961": { rate: 0.39, currency: "EUR", country: "Lebanon" },
    "+962": { rate: 0.28, currency: "EUR", country: "Jordan" },
  };

  // Calculate SMS cost based on Unicode character count
  const calculateSmsCost = (chars: number): number => {
    if (chars <= 70) return 1;
    if (chars <= 134) return 2;
    if (chars <= 201) return 3;
    if (chars <= 268) return 4;
    if (chars <= 335) return 5;
    if (chars <= 403) return 6;
    return Math.ceil(chars / 67);
  };

  const fetchNotifies = async (): Promise<void> => {
    try {
      setLoading(true);
      setError("");
      const response = await api.get<Notify[]>("notifing/notify/");
      const data = response.data;
      setNotifies(data);
      setFilteredNotifies(data);

      // Generate country stats
      const stats: { [key: string]: CountryStats } = {};
      let totalEuroSpend = 0;
      let totalEgpSpend = 0;

      data.forEach((notify) => {
        const countryCode = notify.country_code || "Unknown";
        const rateInfo = CURRENCY_RATES[countryCode] || { rate: 0, currency: "Unknown" };
        const messageCost = calculateSmsCost(notify.content.length);
        const cost = messageCost * rateInfo.rate;

        if (!stats[countryCode]) {
          stats[countryCode] = {
            country_code: countryCode,
            currency: rateInfo.currency,
            deliveredTotal: 0,
            undeliveredTotal: 0,
            spent: 0,
          };
        }

        if (notify.delivered) {
          stats[countryCode].deliveredTotal += 1;
          stats[countryCode].spent += cost;

          if (rateInfo.currency === "EUR") totalEuroSpend += cost;
          if (rateInfo.currency === "EGP") totalEgpSpend += cost;
        } else {
          stats[countryCode].undeliveredTotal += 1;
        }
      });

      setCountryStats(Object.values(stats));
      setEuroSpend(totalEuroSpend);
      setEgpSpend(totalEgpSpend);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch notify records.");
    } finally {
      setLoading(false);
    }
  };

  // Handle search and filter logic
  useEffect(() => {
    const filtered = notifies.filter((notify) => {
      // Parse the dates and adjust the range
      const adjustedFromDate = fromDate ? new Date(fromDate) : null;
      const adjustedToDate = toDate ? new Date(toDate) : null;
  
      if (adjustedFromDate) {
        adjustedFromDate.setDate(adjustedFromDate.getDate() ); // Include one day before
      }
      if (adjustedToDate) {
        adjustedToDate.setDate(adjustedToDate.getDate() ); // Include one day after
      }
  
      // Check if the notification matches the search query
      const matchesSearch =
        notify.child_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        notify.phone_number.includes(searchQuery);
  
      // Check if the notification matches the delivery filter
      const matchesFilter =
        filterDelivered === "all" ||
        (filterDelivered === "delivered" && notify.delivered) ||
        (filterDelivered === "undelivered" && !notify.delivered);
  
      // Check if the notification date is within the adjusted date range
      const notifyDate = new Date(notify.created_at); // Ensure `created_at` is a valid date
      const matchesDate =
        (!adjustedFromDate || notifyDate >= adjustedFromDate) &&
        (!adjustedToDate || notifyDate <= adjustedToDate);
  
      // Return true if all conditions are met
      return matchesSearch && matchesFilter && matchesDate;
    });
  
    // Update the filtered notifications state
    setFilteredNotifies(filtered);
  }, [searchQuery, filterDelivered, fromDate, toDate, notifies]);
  
  useEffect(() => {
    fetchNotifies();
  }, []);

  if (loading) return <p className="text-center text-lg text-gray-500">Loading...</p>;
  if (error) return <p className="text-center text-red-600">{error}</p>;

  return (
    <div className="p-6 bg-white shadow rounded-lg max-w-7xl mx-auto">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Notify List and Spending Summary</h2>

      {/* Filters */}
      <div className="mb-6 flex gap-4">
        <input
          type="text"
          placeholder="Search by name or phone..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border rounded-lg px-3 py-2"
        />
        <select
          value={filterDelivered}
          onChange={(e) => setFilterDelivered(e.target.value)}
          className="border rounded-lg px-3 py-2"
        >
          <option value="all">All</option>
          <option value="delivered">Delivered</option>
          <option value="undelivered">Undelivered</option>
        </select>
        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className="border rounded-lg px-3 py-2"
        />
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className="border rounded-lg px-3 py-2"
        />
      </div>

      {/* Spending Summary */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold text-gray-800">Spending Summary</h3>
        <p className="text-sm text-gray-600">Total Euro Spend: {euroSpend.toFixed(2)} EUR</p>
        <p className="text-sm text-gray-600">Total EGP Spend: {egpSpend.toFixed(2)} EGP</p>
      </div>

      {/* Country Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-6">
        {countryStats.map((stat) => (
          <div
            key={stat.country_code}
            className="bg-indigo-100 p-4 rounded-lg shadow"
          >
            <h3 className="text-lg font-semibold text-gray-800">
              {stat.country_code}
            </h3>
            <p className="text-sm text-gray-600">
              Delivered: {stat.deliveredTotal}
            </p>
            <p className="text-sm text-gray-600">
              Undelivered: {stat.undeliveredTotal}
            </p>
            <p className="text-sm text-gray-600">
              Amount Spent (Delivered): {stat.spent.toFixed(2)} {stat.currency}
            </p>
          </div>
        ))}
      </div>

      {/* Notifications Table */}
      <div className="overflow-x-auto">
        <table className="w-full table-auto bg-white border border-gray-200 rounded-md shadow">
          <thead>
            <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">ID</th>
              <th className="py-3 px-6 text-left">Child Name</th>
              <th className="py-3 px-6 text-left">Message Type</th>
              <th className="py-3 px-6 text-left">Content</th>
              <th className="py-3 px-6 text-left">SMS Parts</th>
              <th className="py-3 px-6 text-left">Phone</th>
              <th className="py-3 px-6 text-left">Delivered</th>
              <th className="py-3 px-6 text-left">Created At</th>
            </tr>
          </thead>
          <tbody>
            {filteredNotifies.map((notify) => (
              <tr
                key={notify.id}
                className="border-b border-gray-200 hover:bg-gray-50"
              >
                <td className="py-3 px-6">{notify.id}</td>
                <td className="py-3 px-6">{notify.child_name}</td>
                <td className="py-3 px-6">{notify.message_type}</td>
                <td className="py-3 px-6">{notify.content}</td>
                <td className="py-3 px-6">{calculateSmsCost(notify.content.length)}</td>
                <td className="py-3 px-6">{notify.phone_number}</td>
                <td className="py-3 px-6">
                  {notify.delivered ? "Yes" : "No"}
                </td>
                <td className="py-3 px-6">
                  {new Date(notify.created_at).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NotifyList;
