import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Session, useApi } from '../../context/ApiContext';

interface SessionCardProps {
  session: Session;
}

const SessionCard: React.FC<SessionCardProps> = ({ session }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteInput, setDeleteInput] = useState('');
  const [err, setErr] = useState('');
  const [deletingSuccess, setDeletingSuccess] = useState(false); // New state for deletion success message
  const [isLoading, setIsLoading] = useState(false); // New state for loading indicator

  const [formData, setFormData] = useState<Partial<Session>>({
    title: session.title,
    description: session.description,
    start_time: session.start_time,
    end_time: session.end_time,
    meeting_link: session.meeting_link,
    is_Started: session.is_Started,
    is_ended: session.is_ended,
  });

  const { fetchSessions } = useApi(); // Access API context

  useEffect(() => {
    // Reset form data when session changes
    setFormData({
      title: session.title,
      description: session.description,
      start_time: session.start_time,
      end_time: session.end_time,
      meeting_link: session.meeting_link,
      is_Started: session.is_Started,
      is_ended: session.is_ended,
    });
  }, [session]);

  const updateSession = async (id: number, data: Partial<Session>) => {
    try {
      const updatedData = { ...data, course: session.course };
      await axios.put(`https://codeoceantech.pythonanywhere.com/api/sessions/${id}/`, updatedData);
      fetchSessions();
    } catch (error) {
      console.error('Error updating session:', error);
    }
  };

  const deleteSession = async (id: number) => {
    try {
      setIsLoading(true); // Show loading message
      await axios.delete(`https://codeoceantech.pythonanywhere.com/api/sessions/${id}/`);
      setDeletingSuccess(true); // Show success message
      fetchSessions();
      setIsLoading(false); // Hide loading message
      setTimeout(() => {
        setDeletingSuccess(false); // Hide success message after 3 seconds
      }, 3000);
    } catch (error) {
      console.error('Error deleting session:', error);
      setIsLoading(false); // Hide loading message
      setErr('An error occurred while deleting the session. Please try again.');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    updateSession(session.id, formData);
    setIsEditing(false);
  };

  const handleDelete = () => {
    if (deleteInput === session.title) {
      deleteSession(session.id);
      setIsDeleting(false);
      setDeleteInput('');
      setErr('');
    } else {
      setErr('Session title does not match. Please try again.');
    }
  };

  return (
    <div className={`bg-white shadow-lg rounded-lg border border-gray-200 h-full duration-500  ${isDeleting ? 'min-h-[510px] ': isEditing ? 'min-h-[490px]':  'min-h-[400px]' } `}>
      <div className="p-4">
        {isEditing ? (
          <>
            <input
              type="text"
              name="title"
              value={formData.title || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
            />
            <textarea
              name="description"
              value={formData.description || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
              rows={3}
            />
            <input
              type="datetime-local"
              name="start_time"
              value={formData.start_time?.substring(0, 16) || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
            />
            <input
              type="datetime-local"
              name="end_time"
              value={formData.end_time?.substring(0, 16) || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
            />
            <input
              type="text"
              name="meeting_link"
              value={formData.meeting_link || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
            />
            <div className="flex items-center mb-4">
              <label className="mr-2">Status:</label>
              <select
                name="is_Started"
                value={formData.is_Started ? 'true' : 'false'}
                onChange={e => setFormData(prev => ({ ...prev, is_Started: e.target.value === 'true' }))}
                className="p-2 border rounded"
              >
                <option value="true">Started</option>
                <option value="false">Not Started</option>
              </select>
            </div>
            <div className="flex items-center mb-4">
              <label className="mr-2">Ended:</label>
              <select
                name="is_ended"
                value={formData.is_ended ? 'true' : 'false'}
                onChange={e => setFormData(prev => ({ ...prev, is_ended: e.target.value === 'true' }))}
                className="p-2 border rounded"
              >
                <option value="true">Ended</option>
                <option value="false">Not Ended</option>
              </select>
            </div>
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Save
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="bg-gray-500 text-white p-2 rounded ml-2"
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">{session.title}</h2>
            <p className="text-gray-600 mb-4">{session.description}</p>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">Start Time:</span>
              <span className="ml-2 text-gray-600">{session.start_time && new Date(session.start_time).toLocaleString()}</span>
            </div>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">End Time:</span>
              <span className="ml-2 text-gray-600">{session.end_time && new Date(session.end_time).toLocaleString()}</span>
            </div>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">{session.meeting_link && session.meeting_link.includes('drive.google') ? 'Video Link' : session.meeting_link}</span>
              <a 
                href={session.meeting_link} 
                className="ml-2 text-blue-500 hover:underline" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {session.meeting_link && session.meeting_link.includes('drive.google') ? 'Video Link' : session.meeting_link}
              </a>
            </div>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">Status:</span>
              <span className={`ml-2 px-2 py-1 text-xs font-semibold rounded-full ${session.is_Started ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {session.is_Started ? 'Started' : 'Not Started'}
              </span>
            </div>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">Ended:</span>
              <span className={`ml-2 px-2 py-1 text-xs font-semibold rounded-full ${session.is_ended ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {session.is_ended ? 'Ended' : 'Not Ended'}
              </span>
            </div>
            <button
              onClick={() => setIsEditing(true)}
              className="bg-yellow-500 text-white p-2 rounded"
            >
              Edit
            </button>
            <button
              onClick={() => setIsDeleting(true)}
              className="bg-red-500 text-white p-2 rounded ml-2"
            >
              Delete
            </button>
          </>
        )}
      </div>

      {isDeleting && (
        <div className="p-4 border-t border-gray-200">
          <p className="text-gray-800 mb-2">Enter the session title to confirm deletion:</p>
          <input
            type="text"
            value={deleteInput}
            onChange={e => setDeleteInput(e.target.value)}
            className="mb-2 p-2 border rounded w-full"
          />
          {err && <p className="text-red-500 text-sm mb-2">{err}</p>}
          <button
            onClick={handleDelete}
            className="bg-red-500 text-white p-2 rounded"
          >
            Confirm Deletion
          </button>
          <button
            onClick={() => {
              setIsDeleting(false);
              setDeleteInput('');
              setErr('');
            }}
            className="bg-gray-500 text-white p-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      )}

      {/* Display success message after deletion */}
      {deletingSuccess && (
        <div className="p-4 border-t border-gray-200 bg-green-100 text-green-800">
          <p>Session deleted successfully! This will be reflected shortly.</p>
        </div>
      )}

      {/* Display loading spinner or message */}
      {isLoading && (
        <div className="p-4 border-t border-gray-200">
          <p>Deleting session, please wait...</p>
        </div>
      )}
    </div>
  );
};

export default SessionCard;
