import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Dropdown from "./Dropdown";
import { Course, useApi } from "../../../../context/ApiContext";
import SubscriptionPreview from "./SubscriptionPreview";
import DropdownFilter from "./DropdownFilter";

// Define types for Child and Request
interface Child {
  id: number;
  name: string;
  courses: string[]; // Course IDs
  completed: boolean;
  is_active?: false;
}

interface Request {
  parentName: string;
  parentNumber: string;
  children: Child[];
}

interface Subscription {
  id: number;
  start_date: string;
  end_date: string;
  subscription_type: "paid" | "gift";
  is_active: boolean;
}

const PendingRequestsTable: React.FC = () => {
  const { parents, child, courses, fetchChildren, fetchParents, fetchCourses } = useApi();
  const [requests, setRequests] = useState<Request[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedCourseFilter, setSelectedCourseFilter] = useState<string>(""); // New state for course filter

  // Format requests with associated children
  useEffect(() => {
    if (parents && child) {
      try {
        const formattedRequests = parents.map((parent) => {
          const parentChildren = child
            .filter((ch) => ch.parent === parent.id)
            .map((ch) => ({
              id: ch.id,
              is_active: ch.is_active,
              name: ch.name,
              courses: ch.courses.map(String),
              completed: ch.courses.length > 0,
            }));

          return {
            parentName: parent.user.username,
            parentNumber: parent.user.phone_number,
            children: parentChildren,
          };
        });
        setRequests(formattedRequests);
      } catch (err) {
        setError("Failed to process data.");
        console.error(err);
      }
    }
    console.log(selectedCourseFilter,'--------------------------------');
  }, [parents, child, selectedCourseFilter]);

  const handleCourseFilterChange = (selectedCourseTitle: string) => {
    setSelectedCourseFilter(selectedCourseTitle);
  };

  // Handle course changes for a specific child
  const handleCourseChange = (
    parentIndex: number,
    childIndex: string,
    selectedTitles: string[]
  ) => {
    const updatedRequests = [...requests];
    const childNew = child.find((childB) => childB.id.toString() === childIndex);
  
    if (childNew) {
      let selectedCourseIds: string[] = [];
  
      if (selectedTitles.length > 0) {
        console.log(selectedTitles);
        // If there are selected titles, map them to course IDs
        selectedCourseIds = selectedTitles
          .map((title) => {
            const course = courses.find((course) => course.title === title);
            return course?.id ? course.id.toString() : undefined;
          })
          .filter(Boolean) as string[]; // Ensure only valid strings are included
      } else {
        // If no titles are selected, set to all course IDs
        // selectedCourseIds = courses.map((course) => course.id.toString());
      }
  
      // Ensure `childNew` has the required properties, and convert courses to string[]
      const updatedChild = {
        ...childNew,
        courses: selectedCourseIds, // courses should be a string[]
        completed: selectedCourseIds.length > 0, // Ensure 'completed' is set
      };
  
      // Update the children array of the parent in updatedRequests
      updatedRequests[parentIndex] = {
        ...updatedRequests[parentIndex], // Preserve the parent properties
        children: updatedRequests[parentIndex].children.map((childItem) =>
          childItem.id === updatedChild.id ? updatedChild : childItem
        ),
      };
  
      setRequests(updatedRequests); // Set the updated state
  
      // Send the course update
      sendCourseUpdate(updatedChild.id.toString(), selectedCourseIds);
    }
  };
  

  const sendCourseUpdate = (childId: string, courseIds: string[]) => {
    const apiUrl = `https://codeoceantech.pythonanywhere.com///api/children/${childId}/update-courses/`;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify({ courses: courseIds }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(`Server error: ${data.error}`);
        } else {
          fetchParents();
          fetchCourses();
          fetchChildren();
        }
      })
      .catch((err) => {
        setError("Error updating courses.");
        console.error(err);
      });
  };

  // Filter requests based on search query and selected course filter
  const filteredRequests = requests.filter((request) =>
    (request.parentName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  request.parentNumber.includes(searchQuery) ||
  request.children.some((child) =>
    child.name.toLowerCase().includes(searchQuery.toLowerCase())
)) &&
(selectedCourseFilter === "" || selectedCourseFilter === undefined ||
  request.children.some((child) =>
    child.courses.some(
      (courseId) =>
            courses.find((course) => course.id.toString() === courseId)?.title === selectedCourseFilter
        )
      ))
  );

  return (
    <div className="bg-white shadow rounded-lg p-4">
      {error && (
        <div className="bg-red-200 text-red-700 p-3 mb-4 rounded">{error}</div>
      )}

      {/* Search Input */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by parent name, number, or child name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />

        <DropdownFilter
          selectedValues={selectedCourseFilter ? [selectedCourseFilter] : []}
          options={["", ...courses.map((course) => course.title)]}
          onChange={(selectedTitles) => handleCourseFilterChange(selectedTitles[0])}
          placeholder="Filter by course"
        />
      </div>

      <table className="w-full border-separate border-spacing-0 border border-gray-300">
        <thead>
          <tr className="bg-gray-200 border-b border-gray-300">
            <th className="p-3 text-left">Parent Name</th>
            <th className="p-3 text-left">Parent Number</th>
            <th className="p-3 text-left">Child</th>
            <th className="p-3 text-left">Subscription</th>
            <th className="p-3 text-left">Courses</th>
            <th className="p-3 text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredRequests.map((request, parentIndex) =>
            request.children.map((child, childIndex) => {
              const selectedCourseTitles = child.courses.map(
                (courseId) =>
                  courses.find((course) => String(course.id) === courseId)
                    ?.title || ""
              );

              return (
                <tr
                  key={`${parentIndex}-${childIndex}`}
                  className="border-b border-gray-200 items-center align-middle"
                >
                  <td className="p-3">{request.parentName}</td>
                  <td className="p-3">{request.parentNumber}</td>
                  <td className="p-3">{child.name}</td>
                  <td className="p-3">
                    <SubscriptionPreview childName={child.name} child={child.id} />
                  </td>
                  <td className="p-3">
                    <Dropdown
                      selectedValues={selectedCourseTitles}
                      options={courses.map((course) => course.title)}
                      onChange={(selectedTitles) =>
                        handleCourseChange(parentIndex, child.id.toString(), selectedTitles)
                      }
                    />
                  </td>
                  <td className="p-3 text-center">
                    {child.completed ? (
                      <span className="text-green-500 flex items-center">
                        <FaCheckCircle className="mr-1" /> Completed
                      </span>
                    ) : (
                      <span className="text-red-500 flex items-center">
                        <FaTimesCircle className="mr-1" /> Not Completed
                      </span>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PendingRequestsTable;
