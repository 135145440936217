import React, { useState } from 'react';
import {
  FaBars, FaTimes, FaHome, FaMale, FaCogs, FaUser, FaCreativeCommonsSa, FaAngry, FaMemory,
} from 'react-icons/fa';
import { logo } from '../assets/images';
import { Link } from 'react-router-dom';

const navigation = [
  {
    category: 'Users',
    items: [
      { name: 'Instructors', href: '/instructors', icon: <FaMale /> },
      { name: 'Parents', href: '/parents', icon: <FaMale /> },
      { name: 'Children', href: '/children', icon: <FaMale /> },
      { name: 'User', href: '/user', icon: <FaUser /> },
    ],
  },
  {
    category: 'System',
    items: [
      { name: 'Courses', href: '/courses', icon: <FaCogs /> },
      { name: 'FeedBacks', href: '/feedBacks', icon: <FaCreativeCommonsSa /> },
      { name: 'Problems', href: '/problems', icon: <FaAngry /> },
      { name: 'NotifyList', href: '/NotifyList', icon: <FaMemory /> },
      { name: 'Payments', href: '/payments', icon: <FaCogs /> },
    ],
  },
];

interface AppSidebarProps {
  isOpen: boolean;
  isUnfoldable: boolean;
  onSidebarToggle: () => void;
  onSidebarUnfoldableToggle: () => void;
}

const AppSidebar: React.FC<AppSidebarProps> = ({
  isOpen,
  isUnfoldable,
  onSidebarToggle,
  onSidebarUnfoldableToggle,
}) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const toggleDropdown = (category: string) => {
    setExpanded((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('parent');
    localStorage.removeItem('user_info');
    localStorage.removeItem('role');
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-gray-800 text-white shadow-lg transition-all duration-300 ease-in-out ${
        isOpen ? '-translate-x-0' : '-translate-x-52'
      } ${isUnfoldable ? 'w-64' : 'w-20'} z-50`}
    >
      <>
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <div
            className={`flex items-center transition-opacity ${
              isOpen ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <img src={logo} alt="Logo" className="h-8 w-auto" />
          </div>
          <button className="text-white text-2xl" onClick={onSidebarToggle}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <nav className="mt-4">
          <ul className="space-y-4">
            {/* Standalone Home Item */}
            <li>
              <Link
                to="/home"
                className="flex items-center px-4 py-2 hover:bg-gray-700 rounded group"
              >
                <span className="text-xl mr-2">
                  <FaHome />
                </span>
                <span
                  className={`text-base font-medium ${
                    isOpen ? 'block' : 'hidden'
                  }`}
                >
                  Home
                </span>
              </Link>
            </li>
            {/* Dropdown Categories */}
            {navigation.map((group) => (
              <li key={group.category}>
                <button
                  onClick={() => toggleDropdown(group.category)}
                  className="flex justify-between items-center w-full px-4 py-2 text-left text-sm font-semibold text-white bg-gray-700 hover:bg-gray-600 rounded"
                >
                  <span>{group.category}</span>
                  <span>{expanded[group.category] ? '-' : '+'}</span>
                </button>
                {expanded[group.category] && (
                  <ul className="mt-2 space-y-2">
                    {group.items.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className="flex items-center px-6 py-2 hover:bg-gray-700 rounded"
                        >
                          <span className="text-xl mr-2">{item.icon}</span>
                          <span className="text-base font-medium">
                            {item.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className="absolute bottom-0 left-0 w-full border-t border-gray-700">
          <button
            className="w-full py-2 text-white bg-gray-900"
            onClick={onSidebarUnfoldableToggle}
          >
            {isUnfoldable ? 'codeocean' : 'Expand'}
          </button>
          <button
            className="w-full py-2 mt-2 text-white bg-red-700 hover:bg-red-800"
            onClick={clearLocalStorage}
          >
            Logout
          </button>
        </div>
      </>
    </div>
  );
};

export default React.memo(AppSidebar);
